import { IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonHeader, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import './Payment.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { GetPaymentAsync, Payment } from '../../lib';

export default function PaymentPage() {
    const { merchantId, paymentId } = useParams<{ merchantId: string, paymentId: string }>();
    const [payment, setPayment] = useState<Payment>();

    async function getPaymentAsync() {
        var result = await GetPaymentAsync(paymentId);
        setPayment(result!);
    };

    useEffect(() => {
        getPaymentAsync();
    }, []);

    return (
        <IonPage>
            <IonHeader>

                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle className='paylinkResultMainTitle'>Paylink Search</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRow><IonCol>
                    <IonCard>
                        <IonCardContent>
                            <p className='paylinkResultTitle'>Payment Details</p>
                            <br></br>
                            <p className='paylinkResultDataTitle'><b>Payment ID:</b></p>
                            <p className='paylinkResultData'> {paymentId}</p>
                            <p className='paylinkResultDataTitle'><b>Merchant ID:</b></p>
                            <p className='paylinkResultData'> {merchantId}</p>
                            <p className='paylinkResultDataTitle'><b>Payment Date:</b></p>
                            <p className='paylinkResultData'> {payment?.created}</p>
                            <p className='paylinkResultDataTitle'><b>Amount:</b></p>
                            <p className='paylinkResultData'> {payment?.currency} {payment?.amount.toFixed(2)}</p>
                            <p className='paylinkResultDataTitle'><b>Status:</b></p>
                            <p className='paylinkResultDataStatus'> {payment?.status}</p>
                            <p className='paylinkResultDataTitle'><b>Description:</b></p>
                            <p className='paylinkResultData'> {payment?.description}</p>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};