import { InfiniteScrollCustomEvent, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonDatetime, IonGrid, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonList, IonMenuButton, IonModal, IonNote, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner, IonToolbar } from '@ionic/react';
import { format } from 'date-fns';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { GetUserAsync, ListPayments, Payment, PaymentMethod, User } from '../../lib';
import PaymentDetailsModal from './PaymentDetailsModal';
import './PaymentHistory.css';
import { useParams } from 'react-router';

export type TransactionListProps = {
    subMerchantName?: string,
};

interface ViewParams {
    userId: string,
};

export default function PaymentHistory(props: TransactionListProps) {
    const { userId } = useParams<ViewParams>();
    const [disableInfiniteScroll, setdisableInfiniteScroll] = useState<boolean>(false);
    const [continuation, setContinuation] = useState<string>();
    const [selectedPayment, setSelectedPayment] = useState<Payment>();
    const [status, setStatus] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [customerDetails, setCustomerDetails] = useState<string | undefined>();
    const [start, setStart] = useState<string | undefined>();
    const [end, setEnd] = useState<string | undefined>();
    const [dateStrings, setDateStrings] = useState<string[]>([]);
    const [showDateRangeModel, setShowDateRangeModel] = useState<boolean>(false);
    const [showPaymentHistorySpinner, setshowPaymentHistorySpinner] = useState<boolean>(false);
    const [rangeEnable, setRangeEnable] = useState<boolean>(false);
    const [paymentType, setPaymentType] = useState<string | undefined>();
    const lockApiCalls = useRef(false);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [user, setUser] = useState<User>();
    const completePayments = payments.filter(payment => payment.status === 'COMPLETE');
    const failedPayments = payments.filter(payment => payment.status === 'FAILED');
    const startedPayments = payments.filter(payment => payment.status === 'STARTED');
    const pendingPayments = payments.filter(payment => payment.status === 'PENDING');
    const completeCardPayments = payments.filter(payment => payment.paymentMethod === PaymentMethod.Card && payment.status === 'COMPLETE');
    const completeObPayments = payments.filter(payment => payment.paymentMethod === PaymentMethod.OpenBanking && payment.status === 'COMPLETE');
    

    const totalAmount = completePayments.reduce((acc, payment) => {
        // Parse the payment amount to a float, if it's not a valid number, return 0
        const amount = parseFloat(payment.amount.toString());
        // Check if the parsed amount is a valid number
        if (!isNaN(amount)) {
            // If it's a valid number, add it to the accumulator
            return acc + amount;
        } else {
            // If it's not a valid number, return the accumulator unchanged
            return acc;
        }
    }, 0);


    var currentDateStrings = [] as string[];

    async function getHistoryAsync(ev?: InfiniteScrollCustomEvent) {
        try {
            if (userId) {
                setshowPaymentHistorySpinner(true);

                let continuationToken = continuation;
                if (!ev) {
                    continuationToken = undefined;
                }
                var response = await ListPayments(userId, continuationToken!, 50, status, customerDetails?.toLowerCase(), description?.toLowerCase(), start, end, props.subMerchantName, paymentType);
                setshowPaymentHistorySpinner(false);

                if ((response?.data?.length ?? 0) === 0) {
                    if (response.continuation === null) setPayments([]);
                    return;
                }
                setPayments(continuationToken ? ([...payments!, ...response.data]) : response.data);
                setContinuation(response.continuation!);
                if (payments.length >= 150) setdisableInfiniteScroll(true);
                if (ev) ev.target.complete();
            }
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    useEffect(() => {
        // two levels of preventing subsequent queries needed here -
        // one for if a call has gone out but the state is not yet populated (lockApiCalls)
        // and another if all the api calls have gone out but states are in process of being populated (pageStateIsEmpty)
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;
        getHistoryAsync();
    }, [dateStrings]);

    // changing the user id clears the list and fetches the user again
    // whether by url or user action against the context i.e.from search click
    useEffect(() => {
        if (userId) {
            GetUserAsync(userId).then(user => setUser(user!));
            setPayments([]);
        }
    }, [userId]);

    // once the user has been fetched, get the new list 
    useEffect(() => {
        if (userId) {
            getHistoryAsync();
        }
    }, [user]);

    useEffect(() => {
        if (start && end && start == end) {
            setDateStrings([start]);
        }
    }, [start, end]);

    let contentRef = useRef<HTMLIonContentElement | null>(null);
    function scrollToTop() {
        contentRef.current && contentRef.current.scrollToTop();
    };

    function onClickCancelModel() {
        // setSelectedPayment(undefined);
        getHistoryAsync();
        scrollToTop();
    };

    function onClickDateRangeModel() {
        setShowDateRangeModel(false);
    };

    function closeDateRangeModel() {
        setShowDateRangeModel(false);
    }

    function setDatesFromOption(dateCode: string) {
        setRangeEnable(false);
        switch (dateCode) {
            case "1": // Today
                setStart(format(new Date(), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), +1), "yyyy-MM-dd"));
                break;
            case "2": // Yesterday
                setStart(format(addDays(new Date(), -1), "yyyy-MM-dd"));
                setEnd(format(new Date(), "yyyy-MM-dd"));
                break;
            case "3": // This Week
                setStart(format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                setEnd(format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                break;
            case "4": // Last Week
                setStart(format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7), "yyyy-MM-dd"));
                setEnd(format(endOfWeek(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                break;
            case "5": // This Month
                setStart(format(startOfMonth(new Date()), "yyyy-MM-dd"));
                setEnd(format(endOfMonth(new Date()), "yyyy-MM-dd"));
                break;
            case "6": // Last Month
                setStart(format(addMonths(startOfMonth(new Date()), -1), "yyyy-MM-dd"));
                setEnd(format(endOfMonth(addMonths(startOfMonth(new Date()), -1)), "yyyy-MM-dd"));
                break;
            case "7": // Last 7 Days
                setStart(format(addDays(new Date(), -7), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), +1), "yyyy-MM-dd"));
                break;
            case "8": // Last 30 Days
                setStart(format(addDays(new Date(), -30), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), +1), "yyyy-MM-dd"));
                break;
            case "9": // Custom Range
                setRangeEnable(true);
                break;

            default: // All
                setStart(undefined);
                setEnd(undefined);
                break;
        }
    };

    function onClickSearchPaymentHistory() {
        getHistoryAsync();
    };



    function onDescriptionChanged(val: CustomEvent) {
        setDescription(val.detail.value);
    };

    function onDateChanged(val: CustomEvent) {

        dateChange(val.detail.value);
        if (Array.isArray(val.detail.value)) {

            if (val.detail.value.length >= 1) {
                setStart(val.detail.value[0],);
                setEnd(val.detail.value[val.detail.value.length - 1]);
            }
        }
    };

    const onClickCancelPaymentTypeSelect = () => {
        setPaymentType(undefined);
    }

    function onClickCancelStatusSelect() {
        setStatus(undefined);
    };

    function onClickShowDateRangeModel() {
        if (rangeEnable) {
            setShowDateRangeModel(true);
        }
    };

    function dateChange(selectedDate: string[]) {

        if (selectedDate === undefined) {
            setDateStrings([]);
            return;
        }

        if (Array.isArray(selectedDate)) {
            // rest of your code...
        }


        var localDateStrings = selectedDate as string[];
        // Detect changes (prevents an infinite loop).
        if (currentDateStrings === localDateStrings) { return; }

        // Make sure we have at least two dates (otherwise nothing to do).
        if (!(localDateStrings && localDateStrings?.length > 1)) { return; }

        // Sort the dates so the earliest one is first.
        localDateStrings.sort((a, b) => {
            if (a > b) { return 1; }
            else if (a < b) { return -1; }
            else { return 0; }
        });

        // Revert to single selection (if required).
        if (dateStrings && dateStrings?.length > 2) {
            for (const d of dateStrings) {
                if (localDateStrings.indexOf(d) === -1) {
                    // Make sure this is not the first or last date (those are handled natively).
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                    break;
                }
            }

            for (const d of localDateStrings) {
                if (dateStrings.indexOf(d) === -1) {
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                    break;
                }
            }
        }

        // Store the new value.
        const newValue = [];

        // Add all dates between the first and second dates.
        for (let d = new Date(localDateStrings[0]); d <= new Date(localDateStrings[localDateStrings.length - 1]); d.setDate(d.getDate() + 1)) {
            newValue.push(format(d, "yyyy-MM-dd"));
        }

        // Update the values at the end so the UI gets updated only once.
        currentDateStrings = newValue;
        setDateStrings(newValue);
        // Ref https://github.com/ionic-team/ionic-framework/issues/23572#issuecomment-1227543345
    }

    function StatusIcon(paymentStatus: string | undefined) {
        if ((paymentStatus === "PENDING") || (paymentStatus === "PROCESSING") || (paymentStatus === 'STARTED')) {
            return <IonImg src="/assets/icon/hourglassIcon.svg" />
        }
        else if ((paymentStatus === "SUCCESS") || (paymentStatus === "COMPLETE")) {
            return <IonImg src="/assets/icon/greenTickIcon.svg" />
        }
        else {
            return <IonImg src="/assets/icon/redCrossIcon.svg" />
        }
    }

    function PaymentStatusColor(payment: string | undefined) {
        if ((payment === 'PENDING') || (payment === 'PROCESSING') || (payment === 'STARTED')) {
            return 'paymentStatusPendingOrInProgress'
        }
        else if ((payment === 'SUCCESS') || (payment === 'COMPLETE')) {
            return 'paymentStatusSuccess'
        }
        else {
            return 'paymentStatusFailed'
        }
    }

    return <IonPage>
        <IonToolbar >
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonGrid>
                <IonRow className='subscriptionTitleRows'>
                    <IonCol size='auto'>
                        <h1 id='transactionsMainTitle'>Transactions -</h1>
                    </IonCol>
                    <IonCol size='auto'>
                        <h1 id='transactionsUsersName'>{user?.firstName}, {user?.lastName}</h1>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
        <IonContent className='paymentHistoryBlock' ref={contentRef} scrollEvents={true}>
            <IonRow>
                <IonCol sizeXl='8' sizeLg='8' sizeMd='8' sizeSm='8' sizeXs='7'>
                    <IonInput
                        placeholder='Description'
                        className="descriptionInput"
                        onIonChange={onDescriptionChanged}
                        value={description}>
                    </IonInput>
                </IonCol>
                <IonCol sizeXl='1' sizeLg='1' sizeMd='1' sizeSm='3' sizeXs='3'>
                    <IonButton
                        className="searchPaymentsButton"
                        shape="round"
                        color="secondary"
                        size="small"
                        onClick={onClickSearchPaymentHistory}>Search
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs='0' sizeSm='0' sizeMd='1' sizeLg='1' sizeXl='0'></IonCol>
                <IonCol sizeXs='10' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='10'> <div className="paymentHistoryFilter">
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='testFilter' placeholder="Status" interface="popover"
                                onIonChange={(e) => setStatus(e.detail.value)}
                                onIonCancel={onClickCancelStatusSelect}
                                value={status}>
                                <IonSelectOption value="">All</IonSelectOption>
                                <IonSelectOption value="100">Complete</IonSelectOption>
                                <IonSelectOption value="10">Pending</IonSelectOption>
                                <IonSelectOption value="200">Error</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='testFilter' placeholder="Date"
                                interface="popover"
                                onIonChange={(e) => setDatesFromOption(e.detail.value)}>
                                <IonSelectOption value="0">All</IonSelectOption>
                                <IonSelectOption value="1">Today</IonSelectOption>
                                <IonSelectOption value="2">Yesterday</IonSelectOption>
                                <IonSelectOption value="3">This Week</IonSelectOption>
                                <IonSelectOption value="4">Last Week</IonSelectOption>
                                <IonSelectOption value="5">This Month</IonSelectOption>
                                <IonSelectOption value="6">Last Month</IonSelectOption>
                                <IonSelectOption value="7">Last 7 Days</IonSelectOption>
                                <IonSelectOption value="8">Last 30 Days</IonSelectOption>
                                <IonSelectOption value="9">Custom Range</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='testFilter' placeholder="Type" interface="popover"
                                onIonChange={(e) => setPaymentType(e.detail.value)}
                                onIonCancel={onClickCancelPaymentTypeSelect}
                                value={paymentType}>
                                <IonSelectOption value="">All</IonSelectOption>
                                <IonSelectOption value="10">Open Banking</IonSelectOption>
                                <IonSelectOption value="20">Card Payment</IonSelectOption>

                            </IonSelect>
                        </IonItem>
                    </IonList>
                </div>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='2' sizeXl='3' ></IonCol>
                <IonCol sizeXs='5' sizeSm='5' sizeMd='5' sizeLg='4' sizeXl='3'>
                    <IonInput value={start ? format(new Date(start), "dd-MM-yyyy") : ''}
                        placeholder="From"
                        hidden={!rangeEnable}
                        disabled={!rangeEnable}
                        onClick={onClickShowDateRangeModel}
                        className="datePicker">
                    </IonInput>
                </IonCol>

                <IonCol sizeXs='5' sizeSm='5' sizeMd='5' sizeLg='4' sizeXl='3'>
                    <IonInput value={end ? format(new Date(end), "dd-MM-yyyy") : ''}
                        placeholder="To"
                        hidden={!rangeEnable}
                        disabled={!rangeEnable}
                        onClick={onClickShowDateRangeModel}
                        className="datePicker">
                    </IonInput>
                </IonCol>
                <IonCol sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='2' sizeXl='3'></IonCol>
            </IonRow>

            <div className='ion-text-center'>{showPaymentHistorySpinner && <IonSpinner></IonSpinner>}</div>

            <IonCard className='transactionGrid'>
                <IonCardContent>
                    <IonGrid fixed>
                        <IonRow style={{ position: 'sticky' }}>
                            <IonCol><h1 id='transactionsSubTitle'>Transaction History</h1></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol><p style={{ fontSize: '12px', color: '#003466', fontWeight: "bold" }}>Number of Payment Records Retrieved: {payments.length}</p>
                                <p style={{color:'#003466', fontSize:'12px'}}>Completed Open Banking Payments - {completeObPayments.length}</p>
                                <p style={{ color: '#17DA36', fontSize: '12px' }}>Completed - {completePayments.length}</p>
                                <p style={{ color: '#ffa700', fontSize: '12px' }}>Started - {startedPayments.length}</p>   
                            </IonCol>
                            <IonCol>
                                <p style={{ fontSize: '12px', color: '#003466', fontWeight: "bold" }}>Total Value of Currently Retrieved Complete Payments: {totalAmount.toFixed(2)}</p>
                                <p style={{color:'#003466', fontSize:'12px'}}>Completed Card Payments -{completeCardPayments.length} </p>
                                <p style={{ color: '#EA1313', fontSize: '12px' }}>Failed - {failedPayments.length}</p>
                                <p style={{ color: '#ffa700', fontSize: '12px' }}>Pending - {pendingPayments.length}</p>
                            </IonCol>
                        </IonRow>
                        <IonList>
                            <IonRow className='paymentHistorytitleRow'>
                                <IonCol sizeXs='3'><IonNote className='transactionHeader'>Transaction ID</IonNote></IonCol>
                                <IonCol sizeXs='2'><IonNote className='transactionHeader'>Amount</IonNote></IonCol>
                                {/* <IonCol sizeXs='2' sizeSm='2'><IonNote className='transactionHeader'>Invoice</IonNote></IonCol> */}
                                <IonCol sizeXs='4' ><IonNote className='transactionHeader'>Date</IonNote></IonCol>
                                <IonCol sizeXs='3'><IonNote className='transactionHeader'>Status</IonNote></IonCol>
                            </IonRow>
                            {payments?.map((payment, index) => {
                                return (

                                    <IonItem lines="none" className='paymentItem' id={`payment_${payment.id}`} key={payment.id} onClick={() => setSelectedPayment(payment)}>
                                        <IonCol sizeXs='3' className='transactionIDText'>{payment.id}</IonCol>
                                        <IonCol sizeXs='2' className='transactionAmountText'>{payment.amount}</IonCol>
                                        {/* <IonCol sizeXs='2' sizeSm='2' className='transactionText'>TRN_20221009{payment.invoiceId}</IonCol> */}
                                        <IonCol sizeXs='4' className='transactionText'>{payment.clientDateCreated ? payment.clientDateCreated : (payment.created)}</IonCol>
                                        <IonCol sizeXs='3' className='transactionText'><div className='iconDiv'>{StatusIcon(payment.status)}</div>
                                            <div className={PaymentStatusColor(payment.status)}>{payment.status}</div></IonCol>
                                    </IonItem>
                                );
                            })}
                            <IonInfiniteScroll threshold="200px" onIonInfinite={getHistoryAsync} disabled={disableInfiniteScroll}>
                                <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading...">
                                </IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonList>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            <div className='paymentHistoryBlock'></div>
        </IonContent>
        
        <PaymentDetailsModal payment={selectedPayment} onClosed={onClickCancelModel} />

        <IonModal handle={false} backdropDismiss={false} canDismiss={true} isOpen={showDateRangeModel} onDidDismiss={closeDateRangeModel} breakpoints={[0, 0.68]}
            initialBreakpoint={0.68} backdropBreakpoint={0.2}  >
            <IonContent className='dateTimeContent'>
                <br></br>
                <IonDatetime
                    className='datePicker'
                    multiple={true}
                    presentation="date"
                    value={dateStrings}
                    onIonChange={onDateChanged}>
                </IonDatetime>
                <IonButton style={{ width: '120px' }} size="small" shape="round" color="secondary" onClick={() => onClickDateRangeModel()}>Select</IonButton>
            </IonContent>
        </IonModal>
    </IonPage>;
};