import { IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { GetInstructionsAsync, Instruction, InstructionFilter } from "../../lib/data/MessagingData";
import { format } from "date-fns";
import { ButtonRef, SubmitButton, LinkButton } from "../controls/Buttons";
import DatePickerRange, { DateRange } from "../controls/DatePickerRange";
import { InstructionPartyType } from "../../lib/data/MessagingData";
import { useHistory } from "react-router";
import { GetUserIdByMerchantKey } from "../../lib";

export default function InstructionsPage() {
    const history = useHistory();
    const [instructions, setInstructions] = useState<Instruction[]>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [filter, setFilter] = useState<InstructionFilter>({ status: "Failed" });
    const [pendingFilter, setPendingFilter] = useState<InstructionFilter>({ status: "Failed" });
    const [loading, setLoading] = useState(false);
    const submitButtonRef = useRef<ButtonRef>(null);

    useEffect(() => {
        loadInstructions();
    }, []);

    async function loadInstructions(newFilter?: InstructionFilter) {
        if (loading) return;
        setLoading(true);
        try {
            const result = await GetInstructionsAsync(newFilter || filter, 1);
            setInstructions(result.data);
            setPage(1);
            setPageSize(result.pageSize);
            setTotalCount(result.totalCount);
            setHasMore(result.hasMore);
            if (newFilter) {
                setFilter(newFilter);
                setPendingFilter(newFilter);
            }
        } finally {
            setLoading(false);
            submitButtonRef.current?.stopSpinning();
        }
    }

    async function loadMore() {
        if (loading || !hasMore) return;
        setLoading(true);
        try {
            const nextPage = page + 1;
            const result = await GetInstructionsAsync(filter, nextPage);
            setInstructions([...instructions, ...result.data]);
            setPage(nextPage);
            setHasMore(result.hasMore);
        } finally {
            setLoading(false);
        }
    }

    function updatePendingFilter(updates: Partial<InstructionFilter>) {
        setPendingFilter({ ...pendingFilter, ...updates });
    }

    function handleSubmit() {
        loadInstructions(pendingFilter);
    }

    function handleDateRangeChange(dateRange: DateRange) {
        updatePendingFilter({
            startDate: dateRange.startDate || undefined,
            endDate: dateRange.endDate || undefined
        });
    }

    async function navigateToEntity(entityType: string, entityId: string) {
        switch (entityType.toLowerCase()) {
            case "account":
                history.push(`/users/${entityId}`);
                break;
            case "merchant":
                const userId = await GetUserIdByMerchantKey(entityId);
                if (userId) {
                    history.push(`/users/${userId}`);
                }
                break;
        }
    }

    function canNavigateToEntity(entityType: string): boolean {
        return ["account", "merchant"].includes(entityType.toLowerCase());
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Instructions ({totalCount} total)</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="stacked">Type</IonLabel>
                                <IonInput
                                    value={pendingFilter.type}
                                    onIonChange={e => updatePendingFilter({ type: e.detail.value || undefined })}
                                    placeholder="Filter by instruction type"
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="stacked">Status</IonLabel>
                                <IonSelect
                                    value={pendingFilter.status}
                                    onIonChange={e => updatePendingFilter({ status: e.detail.value })}
                                >
                                    <IonSelectOption value={undefined}>All</IonSelectOption>
                                    <IonSelectOption value="Pending">Pending</IonSelectOption>
                                    <IonSelectOption value="Processing">Processing</IonSelectOption>
                                    <IonSelectOption value="Completed">Completed</IonSelectOption>
                                    <IonSelectOption value="Failed">Failed</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="stacked">Entity Type</IonLabel>
                                <IonInput
                                    value={pendingFilter.entityType}
                                    onIonChange={e => updatePendingFilter({ entityType: e.detail.value || undefined })}
                                    placeholder="Filter by entity type"
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="stacked">Entity ID</IonLabel>
                                <IonInput
                                    value={pendingFilter.entityId}
                                    onIonChange={e => updatePendingFilter({ entityId: e.detail.value || undefined })}
                                    placeholder="Filter by entity ID"
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="stacked">Date Range</IonLabel>
                                <DatePickerRange
                                    startDate={pendingFilter.startDate}
                                    endDate={pendingFilter.endDate}
                                    onChange={handleDateRangeChange}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <SubmitButton 
                                text="Search Instructions"
                                onClick={handleSubmit}
                                ref={submitButtonRef}
                                disabled={loading}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonList>
                    {loading && instructions.length === 0 ? (
                        <IonItem>
                            <IonLabel className="ion-text-center">
                                <h2><IonSpinner name="dots" /> Loading instructions...</h2>
                            </IonLabel>
                        </IonItem>
                    ) : instructions.length === 0 ? (
                        <IonItem>
                            <IonLabel className="ion-text-center">
                                <h2>No instructions found</h2>
                                <p>Try adjusting your search filters</p>
                            </IonLabel>
                        </IonItem>
                    ) : (
                        instructions.map(instruction => (
                            <IonItem key={instruction.id}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="3">
                                            <IonLabel>
                                                <h2>
                                                    <LinkButton
                                                        onClick={() => history.push(`/instructions/${instruction.id}`)}
                                                        color="secondary"
                                                    >
                                                        {instruction.type}
                                                    </LinkButton>
                                                </h2>
                                                <p>{instruction.status}</p>
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <p>Created: {format(instruction.created, "dd/MM/yyyy HH:mm")}</p>
                                            <p>Updated: {format(instruction.updated, "dd/MM/yyyy HH:mm")}</p>
                                        </IonCol>
                                        <IonCol size="3">
                                            <p>From: {InstructionPartyType[instruction.sender.party]}{instruction.sender.system ? ` (${instruction.sender.system})` : ''}</p>
                                            <p>To: {InstructionPartyType[instruction.recipient.party]}{instruction.recipient.system ? ` (${instruction.recipient.system})` : ''}</p>
                                        </IonCol>
                                        <IonCol size="3">
                                            {instruction.entityType && (
                                                <p>
                                                    {instruction.entityType}: {
                                                        canNavigateToEntity(instruction.entityType) ? (
                                                            <LinkButton
                                                                onClick={() => navigateToEntity(instruction.entityType!, instruction.entityId!)}
                                                                color="secondary"
                                                            >
                                                                {instruction.entityId}
                                                            </LinkButton>
                                                        ) : instruction.entityId
                                                    }
                                                </p>
                                            )}
                                            {instruction.errorMessage && (
                                                <p className="error-message">{instruction.errorMessage}</p>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonItem>
                        ))
                    )}
                </IonList>

                {hasMore && (
                    <SubmitButton
                        text={`Load More (Showing ${instructions.length} of ${totalCount})`}
                        onClick={loadMore}
                        disabled={loading}
                    />
                )}
            </IonContent>
        </IonPage>
    );
} 