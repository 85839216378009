import { IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonToolbar } from '@ionic/react';
import './User.css';

import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import {
    Account, ApiKey, GetAccountAsync, GetApiKeyAsync, GetInvoiceAccountAsync, GetMerchantAsync, GetUserAsync, InvoiceAccount, Merchant, User
} from '../lib';
import HttpAuditButton from './controls/HttpAuditButton';
import { useParams } from 'react-router';

type UserPageState = {
    user: User | null,
    account: Account | null,
    invoiceAccount: InvoiceAccount | null,
    merchant: Merchant | null,
    apiKey: ApiKey | null,
};

export interface UserViewParams {
    userId: string,
};

export default function UserPage() {
    const { userId } = useParams<UserViewParams>();
    const lockApiCalls = useRef(false);
    const [userPageState, setUserPageState] = useState<UserPageState>({
        user: null,
        account: null,
        invoiceAccount: null,
        merchant: null,
        apiKey: null,
    });

    function updateUserPageState(name: keyof UserPageState, value: UserPageState[keyof UserPageState]) {
        setUserPageState((prevState) => {
            if (!prevState) return { [name]: value } as UserPageState;
            return { ...prevState, [name]: value };
        });
    };

    function userPageStateIsEmpty() {
        return userPageState.user === null &&
            userPageState.account === null &&
            userPageState.invoiceAccount === null &&
            userPageState.merchant === null;
    }

    async function getUserAsync() {
        try {
            if (userId) {
                const promises = [
                    GetUserAsync(userId).then(user => updateUserPageState('user', user)),
                    GetAccountAsync(userId).then(account => updateUserPageState('account', account)),
                    GetMerchantAsync(userId).then(merchant => {
                        if (merchant?.apiKeyId) {
                            GetApiKeyAsync(merchant?.apiKeyId).then(apiKey => updateUserPageState('apiKey', apiKey));
                        }
                        updateUserPageState('merchant', merchant);
                    }),
                    GetInvoiceAccountAsync(userId).then(invoiceAccount => updateUserPageState('invoiceAccount', invoiceAccount)),
                ];

                await Promise.all(promises);
            }
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    useEffect(() => {
        // two levels of preventing subsequent queries needed here -
        // one for if a call has gone out but the state is not yet populated (lockApiCalls)
        // and another if all the api calls have gone out but states are in process of being populated (userPageStateIsEmpty)
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        if (userPageStateIsEmpty()) {
            getUserAsync();
        } else {
            lockApiCalls.current = false;
        }
    }, [userPageState])

    useEffect(() => {
        if (userId) {
            // force the state to fire into the associated useEffect regardless of the data 
            setUserPageState(prevState => ({
                ...prevState,
                user: null,
                account: null,
                invoiceAccount: null,
                merchant: null,
            }));
        }
    }, [userId]);

    return (
        <IonPage>
            <IonHeader className='ion-no-border'>

                <IonToolbar >
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>

                    <IonGrid>
                        <IonRow className='titleRows'>
                            <IonCol size='auto'> <h1 id='HomeTitle'>User -</h1></IonCol>
                            <IonCol size='auto'> <h1 id='UsersName'>{userPageState?.user?.firstName}, {userPageState?.user?.lastName}</h1></IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >

                {userPageState?.account && (
                    <IonRow>
                        <IonCol>
                            <HttpAuditButton entityId={userPageState.account.id} entityName='Account' />
                            {userPageState?.account?.merchantId && <HttpAuditButton entityId={userPageState.account.merchantId} entityName='Merchant' />}
                        </IonCol>
                    </IonRow>)}

                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='7' sizeXl='7'>
                        <IonCard className="userDetailsCard">
                            <IonCardContent>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol sizeXs='8' sizeSm='7' sizeMd='8' sizeLg='8' sizeXl='8'><h1 className='detailsTitle'>Client Details</h1></IonCol>
                                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='3' sizeXl='3'></IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='5' sizeXl='5'>
                                            <p><strong>First Name: </strong>{userPageState?.user?.firstName}</p>
                                            <br></br>
                                            <p><strong>Last Name: </strong>{userPageState?.user?.lastName}</p>
                                            <br></br>
                                            <p><strong>Business Name: </strong>{userPageState?.merchant?.name}</p>
                                            <br></br>
                                            <p><strong>Email: </strong>{userPageState?.user?.emails.toString()}</p>
                                        </IonCol>
                                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='7' sizeXl='7'>
                                            <p><strong>Address: </strong>{userPageState?.merchant?.address.addressLine1}</p>
                                            {userPageState?.merchant?.address.addressLine2 ? <p>{userPageState?.merchant?.address.addressLine2}</p> : ''}
                                            <p>{userPageState?.merchant?.address.city}</p>
                                            <p>{userPageState?.merchant?.address.country}</p>
                                            <p>{userPageState?.merchant?.address.postalCode}</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>

                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='5' sizeXl='5'>
                        <IonCard className="userDetailsCard">
                            <IonCardContent>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol sizeXs='8' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='9'>
                                            <h1 className='detailsTitle'>Account Details</h1>
                                        </IonCol>
                                        <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='3'>

                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <p><strong>Account Type: </strong>{userPageState?.account?.merchantType}</p>
                                            <br />
                                            <p><strong>Mandate: </strong>{userPageState?.invoiceAccount?.mandate ? <a href={'https://manage.gocardless.com/mandates/' + userPageState?.invoiceAccount?.mandate} target="_blank" rel="noreferrer">View</a> : ''}</p>
                                            <br />
                                            <p><strong>Bank Account: </strong>{userPageState?.merchant?.accountNumber}</p>
                                            <br />
                                            <p><strong>Bank Sort Code: </strong>{userPageState?.merchant?.sortCode}</p>
                                            <br />
                                            <p><strong>IBAN: </strong>{userPageState?.merchant?.iban}</p>
                                            <br />
                                            <p><strong>Currency: </strong>{userPageState?.merchant?.currency}</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>

            </IonContent>
        </IonPage>
    );
};
