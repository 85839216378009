import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useIonToast } from '@ionic/react';

import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonLabel, IonPage, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';

import { User, FindUsersAsync, FindPaymentsAsync, GetUserIdByMerchantKey } from '../lib';
import { ButtonRef, LinkButton, SubmitButton } from './controls/Buttons';
import { Routes } from '../Routes';

export default function HomePage() {
    const [searchEmail, setSearchEmail] = useState<string>();
    const [searchFirstName, setSearchFirstName] = useState<string>();
    const [searchLastName, setSearchLastName] = useState<string>();
    const [searchPayLink, setSearchPayLink] = useState<string>();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [searchMerchantKey, setSearchMerchantKey] = useState<string>();

    const [users, setUsers] = useState<User[]>([]);
    const usersContinuation = useRef<string | undefined>(undefined);
    const searchButtonSpinnerRef = useRef<ButtonRef>(null);
    const merchantKeyButtonSpinnerRef = useRef<ButtonRef>(null);
    const paymentButtonSpinnerRef = useRef<ButtonRef>(null);

    const history = useHistory();
    const [present] = useIonToast();

    const searchUsersAsync = async (clear: boolean | undefined = false) => {
        if (clear) {
            setShowSpinner(true);
            usersContinuation.current = undefined;
        }

        try {
            var response = await FindUsersAsync({
                firstName: searchFirstName!,
                lastName: searchLastName!,
                email: searchEmail!
            }, usersContinuation.current);

            if (clear) setUsers(response.data);
            else setUsers([...users, ...response.data]);

            usersContinuation.current = response.continuation;
        }
        finally {
            setShowSpinner(false);
            searchButtonSpinnerRef.current?.stopSpinning();
        }
    };

    const searchPaymentsAsync = async (clear: boolean | undefined = false) => {
        try {
            var response = await FindPaymentsAsync({
                payLink: searchPayLink!,
            });

            if ((response?.data?.length ?? 0) === 0) {
                present({
                    message: 'No payments found for the given pay link',
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                return;
            }

            var merchantid = response.data[0].merchantId;
            var paymentid = response.data[0].id;
            history.push(`/merchants/${merchantid}/payments/${paymentid}`);
        }
        finally {
            paymentButtonSpinnerRef.current?.stopSpinning();
        }
    };

    const searchByMerchantKeyAsync = async (clear: boolean | undefined = false) => {
        try {
            var response = await GetUserIdByMerchantKey(searchMerchantKey!);
            if (response == null) {
                present({
                    message: 'No merchant found for the given key',
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger'
                });
                return;
            }

            history.push(`/users/${response}`);
        }
        finally {
            merchantKeyButtonSpinnerRef.current?.stopSpinning();
        }
    };

    const navigateToUser = (userId: string) => {
        history.push(`/users/${userId}`);
    }

    const navigateToPartner = (userId: string) => {
        history.push(Routes.Partner(userId));
    }

    return <IonPage>
        <IonContent color={"#003466"} fullscreen>
            <IonHeader className='ion-no-border'>
                <IonToolbar >
                    <IonTitle color='primary'><h1 id='accountSearchTitle'>Search</h1></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonGrid>

                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='12' sizeXl='5' >
                        <IonRow>
                            <IonCol sizeXs='7' sizeSm='7' sizeMd='4' sizeLg='6' sizeXl='6'>
                                <IonText color="primary">
                                    <h2 className='accountSearchSubTitle'>User Search</h2>
                                </IonText>
                            </IonCol>
                            <IonCol sizeXs='1' sizeSm='1' sizeMd='3' sizeLg='2' sizeXl='2'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='5' sizeLg='3' sizeXl='3'>
                                <div className='accountSearchButton'>
                                    <SubmitButton
                                        ref={searchButtonSpinnerRef}
                                        onClick={() => searchUsersAsync(true)}
                                        text="Search" />
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className='inputRow'>
                            <IonCol sizeXs='4' sizeSm='6' sizeMd='3' sizeLg='3' sizeXl='3'><h3 className='accountInputTitle'>First Name:</h3></IonCol>
                            <IonCol sizeXs='8' sizeSm='6' sizeMd='9' sizeLg='9' sizeXl='9'>

                                <IonInput className='accountSearchInput' value={searchFirstName}
                                    onIonChange={e => setSearchFirstName(e.detail.value!)}></IonInput>
                            </IonCol>
                        </IonRow>
                        <IonRow className='inputRow'>
                            <IonCol sizeXs='4' sizeSm='6' sizeMd='3' sizeLg='3' sizeXl='3'><h3 className='accountInputTitle'>Last Name:</h3></IonCol>
                            <IonCol sizeXs='8' sizeSm='6' sizeMd='9' sizeLg='9' sizeXl='9'>
                                <IonInput className='accountSearchInput' value={searchLastName}
                                    onIonChange={e => setSearchLastName(e.detail.value!)}></IonInput>
                            </IonCol>
                        </IonRow>
                        <IonRow><IonCol><IonText className='accountInputTitle'>- OR -</IonText></IonCol></IonRow>
                        <IonRow className='inputRow'>
                            <IonCol sizeXs='4' sizeSm='6' sizeMd='3' sizeLg='3' sizeXl='3'><h3 className='accountInputTitle'>Email:</h3></IonCol>
                            <IonCol sizeXs='8' sizeSm='6' sizeMd='9' sizeLg='9' sizeXl='9'>
                                <IonInput className='accountSearchInput' value={searchEmail} onIonChange={e => setSearchEmail(e.detail.value!)}></IonInput>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol sizeXs='0' sizeSm='0' sizeMd='0' sizeLg='0' sizeXl='1'></IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='12' sizeXl='6'>
                        <IonRow>
                            <IonCol><IonText color="primary"><h2 className='accountSearchSubTitle'>Payment Search</h2></IonText></IonCol>
                        </IonRow>
                        <IonRow className='inputRow'>
                            <IonCol size='3'>
                                <h3 className='accountInputTitle'>Pay Link:</h3>
                            </IonCol>
                            <IonCol size='7'>
                                <IonInput className='accountSearchInput' value={searchPayLink} onIonChange={e => setSearchPayLink(e.detail.value!)} />
                            </IonCol>
                            <IonCol size='2'>
                                <SubmitButton
                                    ref={paymentButtonSpinnerRef}
                                    class='accountSearchButton'
                                    onClick={() => searchPaymentsAsync()}
                                    text="Search" />
                            </IonCol>
                        </IonRow>
                        <IonRow className='inputRow'>
                            <IonCol size='3'>
                                <h3 className='accountInputTitle'>Merchant Key:</h3>
                            </IonCol>
                            <IonCol size='7'>
                                <IonInput className='accountSearchInput' value={searchMerchantKey} onIonChange={e => setSearchMerchantKey(e.detail.value!)} />
                            </IonCol>
                            <IonCol size='2'>
                                <SubmitButton
                                    ref={merchantKeyButtonSpinnerRef}
                                    class='accountSearchButton'
                                    onClick={() => searchByMerchantKeyAsync()}
                                    text="Search" />
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol><h2 id='resultsTitle'>Results</h2></IonCol>
                    <IonCol />
                    <IonCol />
                </IonRow>
            </IonGrid>

            <div className='resultsArea'>
                <div className='ion-margin-top ion-text-center'>{showSpinner && <IonSpinner></IonSpinner>}</div>

                {!showSpinner && <>
                    <table style={{ color: "#666", width: "100%" }}>
                        <thead>
                            <tr>
                                <th className='itemTitle' style={{ textAlign: "left", paddingLeft: "10px" }}>Name/Email</th>
                                <th className='itemTitle'>Partner</th>
                                <th className='itemTitle'>Payments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? users?.map((user, index) =>
                                <tr id={`user_${user.id}`} key={index}>
                                    <td style={{ padding: "10px" }}>
                                        <IonLabel>
                                            <h2 style={{ color: 'black' }}>{user.lastName}, {user.firstName}</h2>
                                            <p style={{ color: 'black' }}>{user.emails.toString()} ({user.issuer})</p>
                                        </IonLabel>
                                    </td>
                                    <td style={{ color: '#003466', padding: "10px", textAlign: "center", alignContent: "start" }}>
                                        {user.partner == true
                                            ? <>
                                                <img src='/assets/img/YesTick.svg' style={{ width: "16px" }} />
                                                <br />
                                                <LinkButton onClick={() => navigateToPartner(user.id)} color="secondary" style={{ marginTop: "-12px" }}>
                                                    View
                                                </LinkButton>
                                            </>
                                            : <>
                                                <img src='/assets/img/NoCross.svg' style={{ width: "16px" }} />
                                                <br />
                                                <div style={{ fontSize: "14px", color: "#666" }}>View</div>
                                            </>}
                                    </td>
                                    <td style={{ color: '#003466', padding: "10px", textAlign: "center", alignContent: "start" }}>
                                        {user.merchant == true
                                            ? <img src='/assets/img/YesTick.svg' style={{ width: "16px" }} />
                                            : <img src='/assets/img/NoCross.svg' style={{ width: "16px" }} />}
                                        <br />
                                        <LinkButton onClick={() => navigateToUser(user.id)} color="secondary" style={{ marginTop: "-12px" }}>
                                            View
                                        </LinkButton>
                                    </td>
                                </tr>)
                                : <tr><td colSpan={3} style={{ textAlign: "center" }}>No users found</td></tr>
                            }
                            {usersContinuation?.current && <IonButton onClick={() => searchUsersAsync()}>Load More</IonButton>}
                        </tbody>
                    </table>
                </>}
            </div>
        </IonContent>
    </IonPage>;
};