import { ApiGet } from "../";

export type UserSearch = {
    firstName: string,
    lastName: string,
    email: string,
};

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    issuerId: string,
    issuer: string,
    emails: string[],
    merchant: boolean,
    partner: boolean,
};

export type UserList = {
    data: User[],
    continuation: string | undefined,
};

export const FindUsersAsync = async (userSearch: UserSearch, continuation: string | undefined = undefined): Promise<UserList> => {
    if (userSearch.firstName + userSearch.lastName + userSearch.email <= '') return { data: new Array<User>(), continuation: undefined };

    var url = `users?`;
    if (userSearch.firstName > '') url += `firstName=${userSearch.firstName}&`;
    if (userSearch.lastName > '') url += `lastName=${userSearch.lastName}&`;
    if (userSearch.email > '') url += `email=${userSearch.email.replace('+', '%2B')}&`;
    if (continuation) url += `continuation=${continuation}`;

    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return { data: new Array<User>(), continuation: undefined };
    // do something?
}

export const GetUserAsync = async(userId: string): Promise<User | null> => {
    var response = await ApiGet(`users/${userId}`);

    if (response.status === 200) {
        return response?.data;
    }

    return null;
}

export const GetUserIdByMerchantKey = async (merchantKey: string): Promise<string | null> => {
    var response = await ApiGet(`users?merchantKey=${merchantKey}`);

    if (response.status === 200) {
        return response.data.accountId;
    }

    return null;
};

