import { IonApp, IonCol, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonMenu, IonMenuToggle, IonRouterOutlet, IonRow, IonSplitPane, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import "./App.css";
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Theme variables */
import { exitOutline, homeOutline, settingsOutline, mailOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { ApiConfig, GetConfig } from './lib/util/Config';
import InvoiceDetails from './pages/InvoiceDetails';
import InvoiceHistory from './pages/InvoiceHistory';
import Payment from './pages/payments/Payment';
import UsersNav from './pages/UserNav';
import './theme/variables.css';
import GlobalToastProvider from './components/GlobalToastProvider';
import { Logout } from './lib';
import PartnerNav from './pages/partners/PartnerNav';
import SystemToolsPage from './pages/admin/SystemTools';
import InstructionsPage from './pages/instructions/InstructionsPage';

setupIonicReact();

function App() {
    const [config, setConfig] = useState<ApiConfig>();
    useEffect(() => { GetConfig().then(c => setConfig(c)); }, []);

    const paths = [
        { name: 'Search', url: '/', icon: homeOutline },
        { name: 'System Tools', url: '/admin/system-tools', icon: settingsOutline },
        { name: 'Instructions', url: '/admin/instructions', icon: mailOutline },
    ]

    function environmentBannerName() {
        if (config?.isSandbox === true) {
            return 'Sandbox'
        }
        else if (config?.isStaging === true || config?.isDevelopment === true) {
            return 'Staging'
        }
        else {
            return ''
        }
    }

    function hideStagingBanner() {
        if (config?.isDevelopment === true || config?.isStaging === true || config?.isSandbox === true) {
            return 'stagingBanner'
        }
        else {
            return 'ion-hide'
        }
    }

    async function doLogout() {
        await Logout();
        //accountContext?.refresh();
    }

    if (!config) return (<div>Loading...</div>);

    return (
        <IonApp>
            <GlobalToastProvider>
                <IonReactRouter>
                    <div className='backgroundSplit'>
                        <IonSplitPane contentId="main" when="md">

                            <IonMenu contentId="main" >
                                <IonHeader>

                                    <IonToolbar color={"#ffffff"}>
                                        <div className='headerImage'>
                                            <IonImg src='/assets/img/logo.svg'></IonImg>
                                        </div>
                                    </IonToolbar>

                                </IonHeader>
                                <IonContent>
                                    {paths.map((item, index) => (
                                        <IonMenuToggle autoHide={false} key={index}>
                                            <IonItem className='splitmenu' routerLink={item.url} routerDirection="forward">
                                                <IonIcon icon={item.icon} slot="start"></IonIcon>
                                                {item.name}
                                            </IonItem>
                                        </IonMenuToggle>
                                    ))}
                                </IonContent>
                                <IonMenuToggle autoHide={false} key={99}>
                                    <IonItem className='splitmenu' button onClick={() => doLogout()}>
                                        <IonIcon icon={exitOutline} slot="start"></IonIcon>
                                        Logout
                                    </IonItem>
                                </IonMenuToggle>
                            </IonMenu>
                                <IonRouterOutlet id="main">
                                    <Route path="/" component={Home} exact />
                                    <Route path="/users/:userId" component={UsersNav} />
                                    <Route path="/InvoiceHistory/:userId" component={InvoiceHistory} exact />
                                    <Route path="/InvoiceDetails/:userId/:invoiceId" component={InvoiceDetails} exact />
                                    <Route path="/merchants/:merchantId/payments/:paymentId" component={Payment} exact />
                                    <Route path="/partners/:userId" component={PartnerNav} />
                                    <Route path="/admin/system-tools" component={SystemToolsPage} />
                                    <Route path="/admin/instructions" component={InstructionsPage} />
                                </IonRouterOutlet>
                        </IonSplitPane>

                    </div>
                    <IonRow className={hideStagingBanner()}>
                        <IonCol></IonCol>
                        <IonCol>{environmentBannerName()}</IonCol>
                        <IonCol></IonCol>
                    </IonRow>
                </IonReactRouter>
            </GlobalToastProvider>
        </IonApp>
    );
};

export default App;
